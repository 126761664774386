import * as React from "react";
import { graphql } from "gatsby";
import { EventsPageContent } from "../../../components/EventsPageContent";
import { Seo } from "../../../components/Seo";

interface Props {
  data: any;
}

const ProgrammKategoriePage: React.FC<Props> = ({ data }) => {
  return (
    <EventsPageContent
      pageTitle={data.currentEventCategory.title}
      allSanityEventCategories={data.allSanityEventCategories}
      allSanityEvents={data.allSanityEvents}
    />
  );
};

export const Head = ({ location: { pathname }, data }) => (
  <Seo title={data.currentEventCategory.title} relativePageUrl={pathname} />
);

export default ProgrammKategoriePage;

export const query = graphql`
  query ($id: String) {
    allSanityEventCategories(sort: { fields: title, order: ASC }) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    currentEventCategory: sanityEventCategories(id: { eq: $id }) {
      title
      slug {
        current
      }
    }
    allSanityEvents(
      filter: { isPastDay: { eq: false }, eventCategory: { id: { eq: $id } } }
      sort: { fields: eventDate }
    ) {
      nodes {
        title
        slug {
          current
        }
        eventCategory {
          title
          slug {
            current
          }
          defaultTitleImage {
            asset {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                width: 200
                height: 200
              )
            }
          }
        }
        location
        titleImage {
          asset {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              width: 200
              height: 200
            )
          }
        }
        eventDate(formatString: "DD.MM.yyyy")
        eventEndDate(formatString: "DD.MM.yyyy")
      }
    }
  }
`;
