import * as React from "react";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import { SiteLayout } from "./SiteLayout";
import { EventBoxBig } from "./EventBoxBig";

interface Props {
  pageTitle: string;
  allSanityEventCategories: any;
  allSanityEvents: any;
}

export const EventsPageContent: React.FC<Props> = ({
  pageTitle,
  allSanityEventCategories,
  allSanityEvents,
}) => {
  const hasEventsInCurrentCategory = allSanityEvents.nodes.length > 0;

  return (
    <SiteLayout>
      <section className="bg-transparent text-white px-10 md:px-16 py-12 md:p-24">
        <div className="container mx-auto max-w-5xl">
          <h1>{pageTitle}</h1>
        </div>
      </section>
      <section className="bg-white text-slate-700 px-10 md:px-16 py-12 md:p-24">
        <div className="container mx-auto max-w-5xl">
          <div className="flex justify-center flex-wrap gap-4">
            <Link
              className="basis-full xs:basis-auto"
              href="/programm"
              activeClassName="current-page"
            >
              <button className="w-full xs:w-auto">Alle Kategorien</button>
            </Link>
            {allSanityEventCategories.nodes.map((category) => (
              <Link
                key={category.slug.current}
                className="basis-full xs:basis-auto"
                href={`/programm/${category.slug.current}`}
                activeClassName="current-page"
              >
                <button className="w-full xs:w-auto">{category.title}</button>
              </Link>
            ))}
          </div>
          {hasEventsInCurrentCategory ? (
            <div className="mt-4 sm:mt-8 grid grid-cols-1 gap-4">
              {allSanityEvents.nodes.map((event) => {
                const titleImageData =
                  event.titleImage?.asset?.gatsbyImageData ||
                  event.eventCategory.defaultTitleImage?.asset?.gatsbyImageData;

                const dateString = event.eventEndDate
                  ? `${event.eventDate} - ${event.eventEndDate}`
                  : event.eventDate;

                return (
                  <EventBoxBig
                    key={event.slug.current}
                    dateString={dateString}
                    eventName={event.title}
                    categoryName={event.eventCategory.title}
                    linkUrl={`/programm/${event.eventCategory.slug.current}/${event.slug.current}`}
                    titleImage={titleImageData}
                  />
                );
              })}
            </div>
          ) : (
            <p className="py-6 sm:py-12 text-center">
              Zurzeit steht leider kein Anlass für diese Kategorie bevor. Schau
              später nochmals vorbei, vielleicht wird ja bald ein neuer Anlass
              geplant?
            </p>
          )}
        </div>
      </section>
      {!hasEventsInCurrentCategory && (
        <section className="bg-transparent text-white px-10 md:px-16 py-12 md:p-24">
          <div className="container mx-auto max-w-5xl">
            <blockquote className="text-inherit mt-6 mb-3 sm:mt-12 sm:mb-5 first:mt-0 tracking-wider leading-relaxed font-lexend text-3xl sm:text-5xl text-center">
              "Wer geduldig ist, der ist weise; wer aber ungeduldig ist,
              offenbart seine Torheit."
            </blockquote>
            <figcaption className="text-center">
              <cite>Sprüche 14:29</cite>
            </figcaption>
          </div>
        </section>
      )}
    </SiteLayout>
  );
};
