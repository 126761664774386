import * as React from "react";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";

interface Props {
  dateString: string;
  eventName: string;
  categoryName: string;
  linkUrl: string;
  titleImage?: IGatsbyImageData;
}

export const EventBoxBig: React.FC<Props> = ({
  dateString,
  eventName,
  categoryName,
  linkUrl,
  titleImage,
}) => (
  <Link
    className="bg-salmon-400 text-white hover:drop-shadow-xl hover:-translate-y-1 hover:no-underline transition flex flex-row"
    href={linkUrl}
  >
    {titleImage ? (
      <GatsbyImage
        className="hidden xs:block xs:basis-1/4 flex-shrink-0"
        image={titleImage}
        alt={`Titelbild von ${eventName}`}
      />
    ) : (
      <div className="bg-voodoo-700 hidden xs:block xs:basis-1/4 flex-shrink-0 p-4">
        <StaticImage src="../images/logo-farbig.png" alt="exSPIRIence Logo" />
      </div>
    )}
    <div className="basis-full xs:basis-3/4 p-4 min-w-0">
      <h2 className="break-words">{eventName}</h2>
      <span className="block">{categoryName}</span>
      <span className="block">{dateString}</span>
    </div>
  </Link>
);
